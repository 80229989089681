// assets
import {
    IconBrandChrome,
    IconHelp,
    IconAd2,
    IconNotification,
} from '@tabler/icons';

// constant
const icons = { IconBrandChrome, IconHelp, IconAd2, IconNotification };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const notice = {
    id: 'Notice',
    type: 'group',
    children: [
        {
            id: 'WebsiteMenu',
            title: 'Notice',
            type: 'collapse',
            icon: icons.IconAd2,
            children: [
                {
                    id: 'AddNotice',
                    title: 'Add Notice',
                    type: 'item',
                    url: '/admin/notice/add',
                    icon: icons.IconNotification,
                    breadcrumbs: false,
                },
                {
                    id: 'AllNotice',
                    title: 'All Notice',
                    type: 'item',
                    url: '/admin/notice/all',
                    icon: icons.IconNotification,
                    breadcrumbs: false,
                },
            ],
        },
    ],
};

export default notice;
