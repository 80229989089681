import dashboard from './dashboard';
// import pages from './pages';
import Print from './print';
import students from './Students';
import employee from './Employee';
import notice from './notice';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, employee, students, notice, Print],
};

export default menuItems;
