// assets
import {
    IconUsers,
    IconUserPlus,
    IconId,
    IconPrinter,
    IconAd2,
} from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconUserPlus,
    IconId,
    IconPrinter,
    IconAd2,
};

// ==============================|| Employee MENU ITEMS ||============================== //

const Employee = {
    id: 'Employee',
    title: 'Employee',
    type: 'group',
    children: [
        {
            id: 'all-employee',
            title: 'All Employee',
            type: 'collapse',
            icon: icons.IconUsers,
            children: [
                {
                    id: 'school all-employee',
                    title: 'All School Employee',
                    type: 'item',
                    url: '/school/employee/all-employee',
                    icon: icons.IconUsers,
                    breadcrumbs: false,
                },
                {
                    id: 'coaching all-employee',
                    title: 'All Coaching Employee',
                    type: 'item',
                    url: '/coaching/employee/all-employee',
                    icon: icons.IconUsers,
                    breadcrumbs: false,
                },
                // {
                //     id: 'student id card',
                //     title: 'ID Cards',
                //     type: 'item',
                //     url: 'school/employee/id-card',
                //     icon: icons.IconId,
                //     breadcrumbs: false
                // },
                // {
                //     id: 'student admit card',
                //     title: 'Admit Cards',
                //     type: 'item',
                //     url: 'school/employee/admit-card',
                //     icon: icons.IconId,
                //     breadcrumbs: false
                // }
            ],
        },
        {
            id: 'add-new',
            title: 'Add Employee',
            type: 'collapse',
            icon: icons.IconUserPlus,
            children: [
                {
                    id: 'school add-new',
                    title: 'School Employee',
                    type: 'item',
                    url: '/school/employee/add-new',
                    icon: icons.IconUserPlus,
                    breadcrumbs: false,
                },
                {
                    id: 'coaching add-new',
                    title: 'Coaching Employee',
                    type: 'item',
                    url: '/coaching/employee/add-new',
                    icon: icons.IconUserPlus,
                    breadcrumbs: false,
                },
            ],
        },
        // {
        //     id: 'student-id-card',
        //     title: 'Student Id Card',
        //     type: 'item',
        //     url: '/employee/student-id-card',
        //     icon: icons.IconId,
        //     breadcrumbs: false
        // }
    ],
};

export default Employee;
